body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.it-right-zone .social {
  padding-top: 12px;
  margin-bottom: 0;
  display: inline-block;
  height: 48px;
  margin-top: 40px;
}
.it-header-slim-wrapper {
  background: rgb(255, 255, 255);
}
#evidenza .section.section-background-header::before {
  background: url(/src/assets/img/home_argomenti.jpg) no-repeat center;
}
.nzeb{
  font-size:13px !important;
}