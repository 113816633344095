body {
  color:#555;
  background-color:#fff;
  -webkit-transition:all .3s ease 0s;
  -o-transition:all .3s ease 0s;
  transition:all .3s ease 0s;
  position:relative;
  height:auto!important;
  font-weight:400
 }
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.cookiebar {
  min-height:120px;
  vertical-align:baseline;
  text-align:center;
  padding-top:36px;
  font-size:.889em;
  width:100%;
  max-width:none!important;
  display: inline-block;
 }
 .spinner-border-overlay{
   position: fixed;
   width: 100%;
   height: 100%;
   background: white;
   z-index: 10000;
   top: 0;
   left: 0;
   opacity: .6;
   text-align: center;
   vertical-align: middle
 }
 .spinner-border{
   position: relative;
   top: 49%;
   left: 0;

 }
 .cookiebar p {
  margin:0 auto
 }
 .cookiebar .btn-verde {
  font-size:.875em;
  margin:8px 0 8px 16px;
  vertical-align:baseline
 }
 .cookiebar .btn-trasp {
  font-size:.875em;
  margin:8px 0;
  padding-left:0;
  padding-right:0;
  text-decoration:underline;
  vertical-align:baseline
 }
 .card.card-img .img-responsive-wrapper .img-responsive.img-responsive-panoramic {
  padding-bottom: 150px;
}

.imagePrestEnerg{
  margin:auto;
}
.imgPrestEnerg{
    width:100%
}
.smileinverno{
  position: absolute;
  bottom: -15px;
  right:-40px;
  width: 50px;
  z-index:1000
}
.smileestate{
  position: absolute;
  bottom: -15px;
  left:-75px;
  width: 50px;
}
.simClassi{
  text-align:center;
  padding:10px;
  color:#fff
}
.feed-description img{
  max-width:100%;
}
.feed-title{
  min-height: 100px;
}
.feed-description-home img{
  display: none
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.it-right-zone .social {
  padding-top: 12px;
  margin-bottom: 0;
  display: inline-block;
  height: 48px;
  margin-top: 40px;
}
.it-right-zone .social a{
  color:#FFF;
}
.it-header-slim-wrapper {
  background: rgb(255, 255, 255);
}
.hidden {
  display: none !important;
}
#evidenza .section.section-background-header::before {
  background: url(/src/assets/img/home_argomenti.jpg) no-repeat center;
}
.bg-azzurro{
  background-color: #1565c0;
}
.it-header-navbar-wrapper {
  background: #003882;
}
.it-header-center-wrapper {
  background: #003882;
}
.bg-giallo {
  background-color: #e3a909 !important;
  color: #000 !important;
}
.fill-white {
  fill: #FFF !important;
}
.btn-outline-primary {
  box-shadow: none;
}
.mw-300 {
  max-width: 300px;
}
.mw-50{
  max-width: 50%;
}
.mw-70{
  max-width: 70%;
}
.h-450{
  height:450px !important;
}
.top-right-0{
  top:0 !important;
  right:0 !important;
}
.spanfoot{
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 16px;
}
.link-list-wrapper ul li a span {
  margin-right: 0;
}
#mapId{
  width: 100%;
  height:500px;
  background-color: transparent;
  position:absolute;
  top:-100px;
  right:50px;
  outline: none !important;
}
#mappaimmobile{
  width: 100%;
  height:300px;
  background-color: transparent;
  outline: none !important;
}
.popup-fixed {
  position: fixed;
  top: 30% !important;
  left: 60% !important;
  transform: none !important;
  margin: 0;
  border-radius: 0;
  margin: 20px 350px;
  font-size:10px;
}
.popup-fixed h5 {
  font-size:11px !important;
}
.popup-fixed .badge {
  font-size:100%;
}
.leaflet-popup-tip {
  width: 0px;
  height: 0px;
}
.leaflet-popup-close-button{
  color:#000 !important;
}
.servizi{
  background-color:#9c3f02;
}
@media (min-width: 992px) {
  .it-header-slim-wrapper {
    height: 55px;
  }
  .navbar .dropdown-menu {
    min-width: 16rem;
  }
}
@media (max-width: 992px) {
  #mapId {
    width: 100%;
    height: 300px;
    background-color: hsl(0deg 0% 0% / 0%);
    position: relative;
    top: 0;
    right: 0;
    outline: none !important;
  }
  .popup-fixed {
    position: absolute !important;
    top: 10% !important;
    left: 0 !important;
    transform: none !important;
    margin: 0 !important;
    font-size:10px;
  }
  .graficopadd {
    padding-left:0 !important;
    padding-right:0 !important;
  }
}
@media (max-width: 768px) {
  .paddingsearch{
    margin-top: 48px !important;
  }
}
.titgraph{
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: .06rem;
  color: #5b6f82;
}
.google-visualization-tooltip{
  z-index: 1000;
}
.css-zsohrp-placeholder{
  fill: #000 !important;
  color:hsl(0deg 0% 0%) !important;
}